<template>
    <v-app>
        <v-app-bar app color="darkgrey" dark>

            <div class="d-flex align-center">
                <v-img alt="Access Flame" class="shrink mr-2" contain src="./assets/access_flame.png"
                    transition="scale-transition" width="40" />

                <v-img alt="Access Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
                    src="./assets/access_name.png" width="100" />
            </div>

            <v-spacer></v-spacer>

            <v-chip v-if="haveToken" class="mx-1">{{ claims['email'] }}</v-chip>
            <v-chip v-if="isManager" color="primary" class="mx-1">Manager</v-chip>
            <v-chip v-else-if="isEmployee" color="primary" class="mx-1">Employee</v-chip>

            <ManageParts v-if="isEmployee" :api-base="settings.apiBase" :token="token" :parts="parts" @del="deletedPart"
                @error="authedAPIError('delete part', $event)"></ManageParts>
            <AddUser v-if="isManager" :api-base="settings.apiBase" :token="token" :client="settings.clientID"
                :can-add-employee="isManager" @success="userSuccess" @error="authedAPIError('add user', $event)">
            </AddUser>

            <v-btn v-if="!haveToken" class="mx-1" color="primary" href="mailto:sales@accessburner.com">
                <v-icon class="mr-1">
                    mdi-email
                </v-icon>
                Request Account
            </v-btn>
            <v-btn v-if="!haveToken" class="mx-1" color="green" :href="login" :disabled="settings.usersURL == null">
                <v-icon class="mr-1">
                    mdi-login
                </v-icon>
                Log In
            </v-btn>
            <v-btn v-else class="mx-1" color="red" @click="clearToken" :href="logout">
                Log Out
                <v-icon right class="ml-1">
                    mdi-logout
                </v-icon>
            </v-btn>

            <DevSettings v-model="settings" :token="token" @input="storeSettings"></DevSettings>

        </v-app-bar>

        <v-main>
            <v-alert v-model="alert.open" :type="alert.type" class="rounded-t-0" prominent dismissible border="left">{{
                alert.message }}</v-alert>
            <v-row align="center">
                <v-img :src="require('./assets/Access_Combustion.png')" class="my-3" contain height="200" />
            </v-row>
            <v-container class="d-block">
                <v-row class="d-flex">
                    <v-select class="flex-grow-1 mr-4" :items="models" v-model="model" label="Model" item-text="desc"
                        item-value="config" outlined></v-select>
                    <PartSelect :parts="parts" @submit="setConfig" @error="selectPartError" />
                </v-row>
            </v-container>
            <ConfiguratorMain v-if="selectedModel != null" v-model="config" :sauce="selectedModel">
            </ConfiguratorMain>
            <v-row class="d-flex justify-center mb-1">
                <ChildComp v-if="config" :api-base="settings.apiBase" :parts="parts" :config="config" />
            </v-row>
            <v-row class="d-flex justify-center mb-4">
                <AwaitPart v-if="false" :disabled="!apiOk || !config" :api-base="settings.apiBase" :token="token"
                    :config="config" @success="notifySuccess" @error="authedAPIError('await part', $event)" />
                <v-btn class="mx-2" color="info" @click="config = null">Clear</v-btn>
                <v-btn class="mr-2" color="info" :disabled="!config" @click="print">Print/Save</v-btn>
                <AddPart v-if="isEmployee" class="mx-2" :disabled="!apiOk || !config" :api-base="settings.apiBase"
                    :token="token" :config="config" @success="addSuccess"
                    @error="authedAPIError('upload part', $event)" />
            </v-row>
        </v-main>

        <v-footer class="d-flex justify-center">
            Product Terms and Conditions, Product Warranties, and Terms of Service found at <a class="mx-1"
                href="https://www.accessburner.com">www.accessburner.com</a> govern use of this Configuration Tool and
            Generated Documents wherefrom.
        </v-footer>
    </v-app>
</template>

<script>
import AddUser from './components/AddUser.vue'
import ConfiguratorMain from './components/ConfiguratorMain'
import DevSettings from './components/DevSettings.vue'
import AwaitPart from './components/AwaitPart.vue'
import AddPart from './components/AddPart.vue'
import ChildComp from './components/ChildComp.vue'
import ManageParts from './components/ManageParts.vue'
import PartSelect from './components/PartSelect.vue'
import * as api from '@/api'
import * as assembly from '@/assembly'
import globalConfig from './assets/config.json'

export default {
    name: 'App',

    components: {
        ConfiguratorMain,
        AddUser,
        DevSettings,
        AwaitPart,
        AddPart,
        ChildComp,
        ManageParts,
        PartSelect
    },

    data: () => ({
        settings: {
            apiBase: globalConfig.apiBase ?? null,
            clientID: globalConfig.clientID ?? null,
            usersURL: globalConfig.usersURL ?? null,
            forceType: null,
        },
        alert: {
            open: false,
            type: null,
            message: null,
        },
        token: null,
        tokenExp: null,

        model: null,
        sauce: null,
        config: null,
        parts: [],
    }),

    computed: {
        haveToken: function () {
            return /^[-_a-zA-Z0-9]+=*\.[-_a-zA-Z0-9]+=*\.[-_a-zA-Z0-9]+=*$/.test(this.token ?? '')
        },
        claims: function () {
            try {
                const [, payload,] = this.token.split('.', 3)
                return JSON.parse(atob(payload)) || {}
            } catch (err) {
                return {}
            }
        },
        isEmployee: function () {
            switch (this.settings.forceType) {
                case 'c': return false
                case 'e': return true
                case 'm': return true
            }
            return this.claims['cognito:groups']?.some((s) => s.includes('access'))
        },
        isManager: function () {
            switch (this.settings.forceType) {
                case 'c': return false
                case 'e': return false
                case 'm': return true
            }
            return this.claims['cognito:groups']?.includes('access-manager')
        },
        redirect: function () {
            const url = new URL(document.location)
            return encodeURIComponent(url.origin)
        },
        login: function () { return `${this.settings.usersURL}/login?client_id=${this.settings.clientID}&response_type=token&redirect_uri=${this.redirect}` },
        logout: function () { return `${this.settings.usersURL}/logout?client_id=${this.settings.clientID}&logout_uri=${this.redirect}` },

        apiOk: function () { return this.settings.apiBase != null && this.token != null },
        models: function () { return this.sauce != null ? Object.values(this.sauce) : [] },
        selectedModel: function () { return this.sauce != null && this.model != null ? this.sauce[this.model] : null },
    },

    watch: {
        token: function () {
            this.getSauce()
            if (!this.haveToken) {
                return
            }
            if (this.tokenExp != null) {
                console.log('clearing existing session timeout watcher')
                clearTimeout(this.tokenExp)
            }
            const exp = (this.claims.exp ?? 0) * 1000
            const now = Date.now()
            if (exp <= now) {
                // The token is already expired.
                this.clearToken()
                return
            }
            const cb = () => {
                this.clearToken()
                this.setAlert('info', 'Your session expired. Please log in again.')
            }
            this.tokenExp = setTimeout(cb, exp - now)
        },
    },

    methods: {
        enteredConfig({ model, config }) {
            this.model = model
            // ConfiguratorMain doesn't have a model to try to parse the new config
            // with until Vue reacts to the update to this.model to compute the new
            // selectedModel. Set the config on the next tick instead so it can
            // catch up.
            this.$nextTick(() => this.config = config)
        },

        storeSettings() {
            localStorage.setItem('settings', JSON.stringify(this.settings))
        },
        getStoredSettings() {
            const s = localStorage.getItem('settings')
            if (s == null) {
                return
            }
            const j = JSON.parse(s)
            // Use $set to ensure settings are reactive.
            this.$set(this, 'settings', j)
        },
        storeToken() {
            sessionStorage.setItem('id', this.token)
        },
        getStoredToken() {
            this.token = sessionStorage.getItem('id') || null
        },
        clearToken() {
            sessionStorage.removeItem('id')
            this.tokenExp = null
            setTimeout(() => this.token = null)
        },

        async getSauce() {

            const resp = await api.sauce(this.settings.apiBase, { id: this.token })
            if (resp.errorMessage != null) {
                console.error(resp.errorMessage)
                this.setAlert('error', 'An error occurred when loading the model definitions.')
                return
            }
            this.sauce = resp.data
        },
        async getParts() {
            const resp = await api.partsList(this.settings.apiBase)
            if (resp.errorMessage != null) {
                this.partsError(resp)
                return
            }
            if (resp.data == null) {
                console.error('no data from API:', resp)
                return
            }
            this.parts = resp.data
        },

        deletedPart(item) {
            const { config, part } = item
            this.parts = this.parts.filter((v) => v.part !== part)
            const msg = `Deleted part ${part} from config ${config}`
            this.setAlert('success', msg)
        },
        setConfig(config) {
            if (this.sauce == null) {
                return
            }
            for (const [model, sauce] of Object.entries(this.sauce)) {
                if (assembly.parse(config, sauce)) {
                    this.enteredConfig({ model, config })
                    return
                }
            }
        },

        setAlert(type, message) {
            const a = { open: true, type, message }
            Object.assign(this.alert, a)
        },
        userSuccess(p) {
            let msg = ''
            switch (p.type) {
                case 'e':
                    msg = `Added employee ${p.email}.`
                    break
                case 'm':
                    msg = `Added manager ${p.email}.`
                    break
                default:
                    msg = `Added user ${p.email} under organization ${p.org}.`
                    break
            }
            this.setAlert('success', msg)
        },
        notifySuccess(p) {
            this.setAlert('success', `You'll be notified once ${p.config} is available.`)
        },
        addSuccess(p) {
            this.setAlert('success', `Uploaded part ${p.part} for configuration ${p.config}.`)
            this.parts.push(p)
        },
        authedAPIError(op, evt) {
            let msg = ''
            switch (evt.status) {
                case 401:
                    msg = `Couldn't ${op} because your session expired.`
                    this.clearToken()
                    break
                default:
                    msg = `Couldn't ${op}: ${evt.errorMessage}.`
                    break
            }
            this.setAlert('error', msg)
        },
        partsError(r) {
            this.setAlert('error', `Couldn't get parts list: ${r.errorMessage}`)
        },
        selectPartError(p) {
            if (p == null) {
                return
            }
            this.setAlert('error', `No part matches ${p}`)
        },

        print() {
            window.print()
        }
    },

    created() {
        this.getParts()
        this.getStoredSettings()
        // Check for Cognito ID token in the URL fragment.
        if (!document.location.hash) {
            this.getStoredToken()
            return
        }
        const url = new URL(document.location)
        const u = new URLSearchParams(url.hash.substring(1))
        this.token = u.get('id_token')
        // Clear the fragment so the token isn't perpetually visible.
        url.hash = ''
        history.replaceState({}, '', url)
        // Check that the token seems like a token. Otherwise try loading a saved
        // token.
        if (!this.haveToken) {
            console.log("token is", this.token, "- loading from storage instead")
            this.getStoredToken()
        } else {
            this.storeToken()
        }
    },

    beforeMount() {
        // Get sauce only if there's no token so we don't race with the watcher.
        if (!this.haveToken) {
            this.getSauce()
        }
    },
};
</script>
