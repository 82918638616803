<script>
import * as assembly from '@/assembly'

export default {
    name: 'ConfiguratorMain',

    props: {
        sauce: Object,
        value: String,
    },
    data: () => ({
        assembly: {},
    }),
    computed: {
        unset: function () { return this.sauce.optionNames.flatMap((k) => this.assembly[k] == null ? [k] : []) },
        code: function () { return this.unset.length === 0 ? assembly.make(this.assembly, this.sauce) : null },
        options: function () { return assembly.options(this.assembly, this.sauce) },
        varies: function () { return assembly.varies(this.assembly, this.sauce) },
        basePrice: function () {
            if (this.unset.length !== 0) {
                return 0 / 0
            }
            return Object.values(this.options).reduce((acc, { price }) => price != null ? acc + price : null, null)
        },
        pricetag: function () {
            if (this.basePrice === null) {
                return null
            }
            let roundedPrice = this.basePrice + Object.values(this.varies).reduce((acc, { add }) => acc + add, 0)
            return roundedPrice.toFixed(2);
        },
        message: function () {
            if (this.unset.length > 0) {
                return `Please select ${this.unset.join(', ')}`
            }
            if (this.pricetag === null) {
                return `Built Configuration: ${this.code}`
            }
            return `Built Configuration: ${this.code} for $${this.pricetag}`
        },
    },
    watch: {
        sauce: function () {
            this.clear()
        },

        value: function (v) {
            if (v == null) {
                this.clear()
                return
            }
            this.$set(this, 'assembly', assembly.parse(this.value, this.sauce))
        },
        code: function (v) {
            this.$emit('input', v)
        },
    },
    methods: {
        clear() {
            this.$set(this, 'assembly', { ...this.sauce.defaults })
        },
        varyParts(sauce, option, item) {
            const r = new Set()
            // Get the configs that this item varies with.
            if (item.varies != null) {
                for (const { config } of item.varies) {
                    for (const k of Object.keys(config)) {
                        r.add(k)
                    }
                }
            }
            // Add all options that vary with this item.
            for (const k of sauce.optionNames) {
                if (k === option) {
                    // Options can't vary with themselves. Skip.
                    continue
                }
                const m = sauce.options[k].some(({ varies }) => varies?.some(({ config }) => config?.[option] === item.config))
                if (m) {
                    r.add(k)
                }
            }
            const a = new Array(...r.values())
            return a.sort((a, b) => sauce.optionNames.indexOf(a) - sauce.optionNames.indexOf(b)).join(", ")
        },
        textdump() {
            console.log("Print me");
            console.log("Assembly:", this.assembly);
            console.log("Sauce:", this.sauce);

            // Reference the assembly
            let a = this.assembly;

            // Start building the output text with the code header.
            let text = this.code + "\n";

            // NOTE: No actuator counting at this time
            text += "\n"

            // Add the Base information.
            if (a["Base"]) {
                let bases = this.sauce.options['Base']
                for (let b = 0; b < bases.length; b++) {
                    if (bases[b]["config"] == a["Base"]) {
                        console.log(bases[b].desc)
                        text += a["Base"] + " - " + bases[b].desc + "\n";
                        break;
                    }
                }
            } else {
                text += "No Base - ";
            }

            // Define the order in which keys should be processed.
            let keyorder = [
                'Fuel', 'Blower', 'Pilot', 'Scanner', 'Modulation',
                'MOD Base', 'Actuator', 'Position Switch', 'Mount', 'Mounting', 'Filter',
                'Air Flow Switch', 'Construction'
            ];

            // Iterate over each key in the desired order.
            keyorder.forEach(key => {
                // Check if the key exists in the assembly.
                if (Object.prototype.hasOwnProperty.call(a, key)) {
                    // Get the configuration value for this key (for example, "N").
                    let configValue = a[key];

                    // Check that sauce options exist for this key.
                    if (this.sauce && this.sauce.options && this.sauce.options[key]) {
                        // Get the list of options for this key.
                        let options = this.sauce.options[key];
                        let found = false;

                        // Iterate over each option until we find a matching config.
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].config === configValue) {
                                // Append the description to our text.
                                text += `    ${configValue} - ${options[i].desc}\n`;
                                found = true;
                                break; // Stop looking after finding the match.
                            }
                        }

                        // If no matching config was found, you might want to indicate that.
                        if (!found) {
                            text += `[${key}: config ${configValue} not found] `;
                        }
                    } else {
                        // If the sauce options for the key don't exist, note that.
                        text += `[${key} not defined in sauce options] `;
                    }
                }
                // If the key is not in the assembly, simply skip it.
            });

            // Log the final text.
            console.log(text);

            // Optionally, if you want to do something special when actuators is true:
            const blob = new Blob([text], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            window.open(url);
        },
        adjustedPrice(item) {
            //  console.log("Before Price Adjustment:", JSON.stringify(this.sauce, null, 2));

            const modelConfig = this.sauce?.config || '';
            const isLPorRBurner = modelConfig.includes("LP") || modelConfig.startsWith("R");

            let adjustedPrice = isLPorRBurner ? Number((item.price * 1.05).toFixed(2)) : Number(item.price.toFixed(2));

            let updatedSauce = JSON.parse(JSON.stringify(this.sauce));

            Object.keys(updatedSauce.options).forEach(key => {
                updatedSauce.options[key].forEach(option => {
                    if (option.price != null) {
                        option.price = isLPorRBurner ? Number((option.price * 1.05).toFixed(2)) : Number(option.price.toFixed(2));
                    }
                    if (option.varies) {
                        option.varies.forEach(variation => {
                            variation.add = Number((variation.add * 1.05).toFixed(2)); // Ensure add is also a number
                        });
                    }
                });
            });

            //  console.log("After Price Adjustment:", JSON.stringify(updatedSauce, null, 2));

            return adjustedPrice;
        }



        // modelChanged(evt) {
        //     const url = new URL(document.location)
        //     const search = new URLSearchParams(url.search)
        //     search.set('model', evt)
        //     url.search = search
        //     history.replaceState({}, '', url)
        // },
    },
    mounted() {
        if (this.value == null) {
            this.clear()
        }
    },
    // created() {
    // // Check for starting model in the URL.
    // const search = new URLSearchParams(document.location.search)
    // const model = search.get('model')
    // if (model != null && model in this.sauce) {
    //     this.selectedModel = model
    // }
    // },
}
</script>

<template>
    <v-container class="d-block">
        <v-row align="center" justify="space-between" class="mb-2">
            <h3>{{ message }}</h3>
            <v-btn color="grey lighten-2" v-if="this.unset.length == 0" @click="textdump">Text Print</v-btn>
        </v-row>
        <v-row v-for="option in sauce.optionNames" :key="option" class="d-flex flex-grow-1 w-100">
            <v-select :items="sauce.options[option]" v-model="assembly[option]" :label="option" item-text="desc"
                item-value="config" outlined>
                <template #item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    <v-chip small class="mr-4" color="primary">{{ item.config }}</v-chip>
                                    <span>{{ item.desc }}</span>
                                    <v-spacer />
                                    <v-chip v-if="item.price != null" small color="secondary"> ${{ item.price
                                        }}</v-chip>
                                    <v-chip v-if="varyParts(sauce, option, item)" class="ml-4">Price varies with {{
                                        varyParts(sauce, option, item) }}</v-chip>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template #selection="{ item, parent }">
                    <v-row align="center" no-gutters>
                        <v-chip small class="mr-4" color="primary"
                            @hook:mounted="parent.$refs.input.style = 'display: none;'">{{ item.config }}</v-chip>
                        <span>{{ item.desc }}</span>
                        <v-spacer />
                        <v-chip v-if="item.price != null" small color="secondary"> ${{ item.price }}</v-chip>
                    </v-row>
                </template>
            </v-select>
        </v-row>
    </v-container>
</template>
